/* VARIABLES */
@import 'variables';

/* BOOTSTRAP */
@import '~bootstrap/scss/bootstrap';

/* PLUGINS */
@import 'plugins/flash_msg.scss';
@import 'plugins/fontsize.css';
@import 'plugins/partners_slider.scss';
@import 'plugins/svg-icons.scss';
@import 'plugins/tinymce.scss';

/* 
 * COTI STYLE
 */
html {
    scroll-behavior: smooth;
}

/* NAVBAR */
#cotiNav {
  .nav-link {
    color: #555 !important;
    &:hover {
      color: $primary;
    }
  }
}

/* DIVERS */
.desc p {
  margin-bottom: 0.5rem;
}

.flag {
  vertical-align: sub;
}

.notation-det {
  letter-spacing: 0.5rem;
}

/* GO TOP */
#cotiTop {
    position: fixed;
    bottom: 3.6rem;
    right: 0.5rem;
    display: none;
    transition: all 300ms ease-in-out;
    z-index: 1050;
}
