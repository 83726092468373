/* BODY */
$body-bg: #f8fafc;

/* COLOR */
$primary: #337ab7;
$warning: #ff9900;

/* FONT */
$enable-responsive-font-sizes: true;

$font-weight-bold:   700;
$font-weight-bolder: 700;

/* LINK */
$link-hover-decoration: none;

/* CARD */
$card-cap-bg: rgba(#000000, 0.05);
